// extracted by mini-css-extract-plugin
export var container = "takeSurvey-module--container--4SoyT";
export var description = "takeSurvey-module--description--3YfTS";
export var img = "takeSurvey-module--img--o+5gj";
export var leftBlock = "takeSurvey-module--leftBlock--j6kxE";
export var link = "takeSurvey-module--link--KdK4x";
export var rightBlock = "takeSurvey-module--rightBlock--NSfC3";
export var section = "takeSurvey-module--section--PJi9b";
export var textBlock = "takeSurvey-module--textBlock--mryGG";
export var title = "takeSurvey-module--title--ddbhS";
export var titleSm = "takeSurvey-module--titleSm--jHT7K";