import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import type { GatsbyImageProps } from "gatsby-plugin-image"

const imgQuery = graphql`
  {
    mob: file(relativePath: { eq: "Banners/ChangeWorldBanner/mob.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    tab: file(relativePath: { eq: "Banners/ChangeWorldBanner/tab.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    desk: file(relativePath: { eq: "Banners/ChangeWorldBanner/desk.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    large: file(relativePath: { eq: "Banners/ChangeWorldBanner/large.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`

export default function Img(props: Omit<GatsbyImageProps, "image">) {
  const image = useStaticQuery(imgQuery)
  const images = withArtDirection(getImage(image.large)!, [
    {
      media: "(max-width: 599.95px)",
      image: getImage(image.mob)!,
    },
    {
      media: "(max-width: 959.95px)",
      image: getImage(image.tab)!,
    },
    {
      media: "(max-width: 1279.95px)",
      image: getImage(image.desk)!,
    },
  ])

  return <GatsbyImage {...props} image={images} />
}
