// extracted by mini-css-extract-plugin
export var btn = "banner-module--btn--GwoGD";
export var container = "banner-module--container--sxF8m";
export var iconHoff = "banner-module--iconHoff--c13OH";
export var iconHoffFund = "banner-module--iconHoffFund--+ycrU";
export var iconsBlock = "banner-module--iconsBlock--gLajj";
export var img = "banner-module--img--RuP9N";
export var leftContent = "banner-module--leftContent--NUEqp";
export var mobBtn = "banner-module--mobBtn--FQoll";
export var promotion = "banner-module--promotion--zt9r6";
export var promotionText = "banner-module--promotionText--xpkpd";
export var rightContent = "banner-module--rightContent--X7ZLI";
export var section = "banner-module--section--axou9";
export var textBlock = "banner-module--textBlock--XVACA";