function getTitleH1(titleH1: any) {
  const regex = /(&lt;|<)h1(&gt;|>)(.*?)(&lt;|<)\/h1(&gt;|>)/

  return titleH1.match(regex)?.[3] ?? ""
}

export function createSeoData(
  title?: string,
  description?: string,
  upperBannerText = "",
  url = "",
  shortTitle = ""
) {
  return {
    title,
    description,
    url,
    shortTitle,
    ...(upperBannerText && { titleH1: getTitleH1(upperBannerText) }),
  }
}
