import React from "react"
import Container from "@ecom/ui/components/Container"
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "./takeSurvey.module.scss"

export const TakeSurvey = ({ orderNum }: { orderNum: string }) => (
  <section data-exclude={orderNum} className={styles.section}>
    <Container className={styles.container}>
      <div className={styles.leftBlock}>
        <StaticImage
          className={styles.img}
          alt="tree"
          src="./img/tree.png"
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
          layout="constrained"
        />
        <div className={styles.textBlock}>
          <div className={styles.title}>
            Внесите вклад <br /> в развитие вашего города
          </div>
          <div className={styles.description}>
            Пройдите опрос и расскажите, какой парк или аллею вы хотели бы обустроить в своем городе
          </div>
        </div>
      </div>
      <div className={styles.rightBlock}>
        <div className={styles.titleSm}>
          Ваше мнение <br /> очень важно для нас
        </div>
        <a
          className={styles.link}
          href="https://halvacard.ru/poll/menyaem-mir-s-halvoj"
          target="_blank"
          rel="noreferrer"
        >
          Пройти опрос
        </a>
      </div>
    </Container>
  </section>
)
