import React from "react"
import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"

import * as styles from "./banner.module.scss"
import { IconHoff } from "./IconHoff"
import { IconHoffFund } from "./IconHoffFund"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

import { TypeBannerProps } from "../helpers"

const defaultTitle = `
    Меняем мир 
    <span>с Халвой!</span>
`

const defaultDescription = `
    — 12 месяцев рассрочки у всех партнёров
    <br/> 
    — 1% от покупок направим  <br/> на благоустройство вашего города
`

export function Banner({
  buttonText = "Оформить карту",
  title = defaultTitle,
  description = defaultDescription,
  orderNum,
}: TypeBannerProps) {
  return (
    <section data-exclude={orderNum} className={styles.section}>
      <Container className={styles.container}>
        <div className={styles.leftContent}>
          <Img alt="bg image" className={styles.img} />
          <div className={styles.textBlock}>
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
            {buttonText}
          </Button>
        </div>
        <div className={styles.rightContent}>
          <div className={styles.iconsBlock}>
            <IconHoff />
            <IconHoffFund />
          </div>
          <div className={styles.promotion}>
            <p className={styles.promotionText}>
              Срок проведения акции <br /> 28.08.23 - 29.10.23
            </p>
          </div>
        </div>
        <Button onClick={() => handleClickBanner("click_cta")} className={styles.mobBtn}>
          {buttonText}
        </Button>
      </Container>
    </section>
  )
}
