import React from "react"
import * as styles from "./banner.module.scss"

export const IconHoff = () => (
  <svg
    className={styles.iconHoff}
    width="69"
    height="35"
    viewBox="0 0 69 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.4327 8.85749C24.5343 8.85749 20.5649 12.8993 20.5649 17.887V25.7985C20.5649 30.7862 24.5343 34.828 29.4327 34.828C34.3311 34.828 38.3005 30.7862 38.3005 25.7985V17.93C38.3427 12.9423 34.3311 8.85749 29.4327 8.85749ZM29.4327 28.4214C28.4192 28.4214 27.5747 27.5614 27.5747 26.5725V17.199C27.5747 16.1671 28.4192 15.3501 29.4327 15.3501C30.4461 15.3501 31.2485 16.2101 31.2485 17.199V26.5725C31.2907 27.5614 30.4461 28.4214 29.4327 28.4214ZM49.0263 7.65356H50.9266C52.869 7.65356 54.4315 6.40663 54.4315 4.42875C54.4315 2.45086 52.869 1.20393 50.9266 1.20393H48.9841C44.0857 1.20393 40.1163 5.1597 40.1163 10.1474V31.3452C40.1163 33.3231 41.6787 34.914 43.6212 34.914C45.5637 34.914 47.1261 33.3231 47.1261 31.3452V17.328H49.5753C51.5177 17.328 53.0802 16.0811 53.0802 14.1032C53.0802 12.1253 51.5177 10.8354 49.5753 10.8354H47.1261V9.71744C47.1683 8.55651 47.8439 7.65356 49.0263 7.65356ZM58.1897 27.0884C56.0361 27.0884 54.3048 28.8514 54.3048 31.0442C54.3048 33.2371 56.0361 35 58.1897 35C60.3433 35 62.0747 33.2371 62.0747 31.0442C62.1169 28.8514 60.3433 27.0884 58.1897 27.0884ZM63.5949 7.65356H65.4951C67.4376 7.65356 69 6.40663 69 4.42875C69 2.45086 67.4376 1.20393 65.4951 1.20393H63.5526C58.6542 1.20393 54.6848 5.1597 54.6848 10.1474V22.3157C54.6848 24.2936 56.2472 25.8845 58.1897 25.8845C60.1322 25.8845 61.6946 24.2936 61.6946 22.3157V17.328H64.1438C66.0863 17.328 67.6487 16.0811 67.6487 14.1032C67.6487 12.1253 66.0863 10.8354 64.1438 10.8354H61.6946V9.71744C61.7368 8.55651 62.4125 7.65356 63.5949 7.65356ZM15.3286 0C13.3862 0 11.8237 1.59091 11.8237 3.5688V10.8354H7.00979V3.5688C7.00979 1.59091 5.44737 0 3.5049 0C1.56242 0 0 1.59091 0 3.5688V31.3022C0 33.2801 1.56242 34.871 3.5049 34.871C5.44737 34.871 7.00979 33.2801 7.00979 31.3022V17.328H10.008C10.9792 17.328 11.7815 18.145 11.7815 19.1339V31.3022C11.7815 33.2801 13.3439 34.871 15.2864 34.871C17.2289 34.871 18.7913 33.2801 18.7913 31.3022V3.5688C18.8758 1.59091 17.2711 0 15.3286 0Z"
      fill="white"
    />
  </svg>
)
