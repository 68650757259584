import React, { useEffect, useState } from "react"
import { graphql, PageProps } from "gatsby"
import getSearchParam from "@ecom/ui/utils/getSearchParam"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/ChangeWorld"
import { Banner as RoundedDynamicBanner } from "../components/Banners/DynamicBanners/RoundedDynamicBanner"
import { CardWithMaxBenefits } from "../components/CardWithMaxBenefits"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { MoreBenefitsRedisign } from "../components/MorBenefitsRedisgn"
import { Partners } from "../components/PartnersNew"
import { Questions } from "../components/Questions"
import { NewFooter } from "../components/NewFooter"

import { getPageData } from "../helpers/getPageData"
import { BannerText, getDynamicBannerText } from "../helpers/getDynamicBannerText"
import { getDynamicBannerUrl } from "../helpers/getDynamicBannerUrl"
import { PageData } from "../interfaces/pageProps"
import { QUESTIONS_FOR_PKW } from "../components/Questions/helpers"
import { InstallmentExample } from "../components/InstallmentExample"
import { TakeSurvey } from "../components/TakeSurvey"
import { createSeoData } from "../templates/helpers/createSeoData"

const pageTitle = `"Меняем мир с Халвой" – помощь в обустройстве городов`
const pageDescription = `Примите участие в акции "Меняем мир с Халвой" и помогите улучшить свои города. Оставьте свой след, выбрав сквер, парк или аллею, которые вы хотите обустроить.`

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const bannerTitle = `
    Меняем мир 
    <span>с Халвой!</span>
`

const bannerDescription = `
    — 12 месяцев рассрочки у всех партнёров
    <br/> 
    — 1% от покупок направим  <br/> на благоустройство вашего города
`

// Время для таймер(30мин)
const countDownTime = 1800000

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const [dynamicBannerTextObj, setDynamicBannerTextObj] = useState<BannerText>()
  const [dynamicBannerImagesObj, setDynamicBannerImagesObj] = useState<any>()

  const bannerTextUrl = getSearchParam("h")
  const bannerImageUrl = getSearchParam("bn")
  const seoData = createSeoData(pageTitle, pageDescription)

  useEffect(() => {
    if (bannerImageUrl) {
      const bannerImagesData = getDynamicBannerUrl(data.allDynamicBanner, bannerImageUrl)
      if (bannerImagesData !== null) {
        setDynamicBannerImagesObj(bannerImagesData)
      }
    }
  }, [data.allDynamicBanner, bannerImageUrl])

  useEffect(() => {
    if (bannerTextUrl) {
      getDynamicBannerText(bannerTextUrl).then((result) => {
        setDynamicBannerTextObj(result)
      })
    }
  }, [bannerTextUrl])

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout seoData={seoData} noHeader noFooter noIndex={noIndex}>
      <NewHeader showHint={false} hasCTA additionalEventInDataLayer countDownTime={countDownTime} />
      {dynamicBannerImagesObj && bannerImageUrl ? (
        <RoundedDynamicBanner
          large={dynamicBannerImagesObj.dymanicBannerDesk}
          mob={dynamicBannerImagesObj.dymanicBannerMob}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          orderNum="1"
        />
      ) : (
        <Banner
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          orderNum="1"
        />
      )}
      <TakeSurvey orderNum="2" />
      <CardWithMaxBenefits
        orderNum="3"
        headTitle={
          <>
            Преимущества <br /> с Халвой:
          </>
        }
      />
      <FormPKW
        dataLayerName="shortPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="4"
      />
      <HowInstallmentWorks pkwOther headTitle="Как работает рассрочка" variant="pkw" orderNum="5" />
      <NewCalculator
        anotherDescr="Данные рассчитываются на основе текущих пользователей."
        additionalEventInDataLayer
        orderNum="6"
      />
      <InstallmentExample withAdditionalBlock orderNum="7" />
      <MoreBenefitsRedisign bottomBlock additionalEventInDataLayer orderNum="8" hasSubtitle />
      <Partners title="Магазины партнеры" orderNum="9" />
      <Questions questionList={QUESTIONS_FOR_PKW} additionalEventInDataLayer orderNum="10" />
      <NewFooter ligal={ligal} orderNum="11" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/menyaem-mir-s-halvoi/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
